<template>
  <v-app>
    <v-main>
      <TopBar />
      <NavBar />
      <MainPage />
      <FooterBar />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import MainPage from "@/components/MainPage.vue";
import FooterBar from "@/views/FooterBar.vue";
import TopBar from "@/components/TopBar.vue";

export default {
  name: "App",

  components: {
    TopBar,
    NavBar,
    MainPage,
    FooterBar,
  },

  data: () => ({
    //
  }),
};
</script>
