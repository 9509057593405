<template>
  <v-footer dark padless height="80">
    <v-container fluid>
      <v-row justify="center" align="center" class="text-center" no-gutters>
        <v-col cols="12" md="4"
          ><a href="https://skiba-procomputer.de" class="text-white"
            >skiba-proComputer.de</a
          >
          © 2023. All Rights Reserved</v-col
        >

        <v-col cols="12" md="6">
          <a href="https://skiba-procomputer.de/#/imprint" target="_blank"
            >Impressum
          </a>
          <span> - </span>
          <a
            href="https://skiba-procomputer.de/#/privacy-policy"
            target="_blank"
          >
            Datenschutz
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "FooterBar",

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.text-white {
  color: white;
  text-decoration: none;
}
</style>
