<template>
  <v-main>
    <section>
      <v-container fluid>
        <v-row align-content="center" justify="center">
          <v-col cols="12" md="12" sm="12">
            <v-img src="@/assets/background-info.svg" height="600px">
              <v-row align-content="center" justify="center">
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                  class="text-style"
                  align-content="center"
                  justify="center"
                >
                  <div class="background-box text-center mt-5">
                    <h2>Unsere Leistungen Lexware als Silberpartner</h2>
                    <ul>
                      <li v-for="(service, index) in services" :key="index">
                        <strong>{{ service }}</strong>
                      </li>
                    </ul>
                  </div>
                  <v-col cols="12" md="12" class="mt-2">
                    <p class="marquee">
                      Planen Sie eine Einführung von Lexware ? Wir sind Ihr
                      Partner ! Beziehen Sie Lexware über Lexware direkt ? Dann
                      buchen Sie doch eher über uns ! Willkommen beim großen
                      Silberpartner von Lexware im MK und HSK !
                    </p>
                    <a
                      href="mailto:info@skiba-proComputer.de?subject=Lexware-Anfrage"
                    >
                      <v-btn rounded color="#004daa" dark class="mx-3">
                        Kontaktaufnahme Lexware
                      </v-btn>
                    </a>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container fluid>
        <v-row align-content="center" justify="center">
          <v-col cols="12" md="12" sm="12">
            <v-img src="@/assets/background_2.jpg" height="600px">
              <v-row align-content="center" justify="center" class="mt-5 pt-5">
                <v-col cols="12" md="6" sm="6" class="text-style">
                  <div class="background-box">
                    <h2>Buchhaltung & Finanzen</h2>
                    <b>
                      Im Bereich Buchhaltung und Finanzen finden Sie hilfreiche
                      Tipps und Praxis-Beispiele für Ihren Unternehmeralltag.
                      Erfahren Sie z.B., wie Sie Ihre Buchhaltung organisieren,
                      die Bilanz erstellen, die GoBD einhalten oder Ihre
                      Finanzen im Blick behalten.
                    </b>
                  </div>
                  <v-col cols="12" md="12" class="mt-2">
                    <a
                      href="https://shop.lexware.de/buchhaltungssoftware?chorid=03308864&em_src=kw&em_cmp=google/00_buchhalter_[]/lexware_buchhaltung/Lexware%20buchhaltung&gad=1&gclid=EAIaIQobChMI9oasn_nv_gIVD5ftCh1kyg5BEAAYASAAEgJtYPD_BwE"
                      target="_blank"
                    >
                      <ButtonDefault />
                    </a>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container fluid>
        <v-row align-content="center" justify="center">
          <v-col cols="12" md="12" sm="12">
            <v-img
              src="@/assets/background_5.jpg"
              class="parallax"
              height="600px"
            >
              <v-row align-content="center" justify="center" class="mt-5 pt-5">
                <v-col cols="12" md="6" sm="6">
                  <div class="background-box">
                    <h1>
                      <strong>Mitarbeiter & Gehalt</strong>
                    </h1>
                    <p>
                      <strong>
                        Die Themen Mitarbeiter und Gehalt stellen essenzielle
                        Pfeiler dar und sind maßgeblich für den Erfolg Ihres
                        Unternehmens. Erfahren Sie hier z.B. mehr zum Thema
                        Arbeitszeit und Zeiterfassung, wie Sie geeignete
                        Mitarbeiter finden, zu Mini-Midijob oder der korrekten
                        Lohn- und Gehaltsabrechnung.</strong
                      >
                    </p>
                  </div>
                  <v-col cols="12" md="12" class="mt-2">
                    <a
                      href="https://shop.lexware.de/lohnabrechnung-gehaltsabrechnung?chorid=03308864&em_src=kw&em_cmp=google/00_loge_[]/lexware_lohn_gehalt/Lexware%20lohn&gad=1&gclid=EAIaIQobChMIhYKEtfnv_gIVlP5RCh15RQHFEAAYASAAEgJd3_D_BwE"
                      target="_blank"
                    >
                      <ButtonDefault />
                    </a>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container fluid>
        <v-row class="m-0" align-content="center" justify="center">
          <v-col>
            <v-img
              src="@/assets/background_3.jpg"
              height="600px"
              class="parallax"
            >
              <v-row align-content="center" justify="center" class="mt-5 pt-5">
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                  style="color: black"
                  class="spacing"
                >
                  <div class="background-box">
                    <h2>Faktura & Warenwirtschaft</h2>
                    <b>
                      In diesem Bereich finden Sie hilfreiche Tipps und
                      Praxis-Beispiele rundum die Themen Faktura und
                      Warenwirtschaft. Erfahren Sie bei Lexware z.B., wie Sie
                      Ihre Warenwirtschaft verwalten, Rechnungen schreiben oder
                      Lagerhaltung und -verwaltung effizient steuern.
                    </b>
                  </div>
                  <v-col cols="12" md="6" class="mt-2">
                    <a
                      href="https://shop.lexware.de/warenwirtschaftssystem?chorid=03308864&em_src=kw&em_cmp=google/00_wawi_[]/lexware_warenwirtschaft/Lexware%20warenwirtschaft&gad=1&gclid=EAIaIQobChMI57zo9_jv_gIVORQGAB2NawjdEAAYASAAEgJNwvD_BwE"
                      target="_blank"
                    >
                      <ButtonDefault />
                    </a>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container fluid>
        <v-row class="m-0" align-content="center" justify="center">
          <v-col>
            <v-img
              src="@/assets/background4.webp"
              class="parallax"
              height="600px"
            >
              <v-row align-content="center" justify="center" class="mt-5 pt-5">
                <v-col cols="12" md="6" sm="6">
                  <div class="background-box">
                    <h2>
                      lexoffice - Automatische Buchhaltung online erledigen
                    </h2>
                    <b>
                      Du kümmerst dich um dein Business, lexoffice erledigt den
                      Rest. Wir machen automatische Lohnabrechnung und
                      Online-Buchhaltung möglich. Von der digitalen
                      Belegerfassung bis zur Rechnungsstellung in Sekunden.
                    </b>
                  </div>
                  <v-col cols="12" md="6" class="mt-2">
                    <a
                      href="https://www.lexoffice.de/?pid=97740917"
                      target="_blank"
                    >
                      <ButtonDefault />
                    </a>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-main>
</template>

<script>
import ButtonDefault from "@/components/ButtonDefault.vue";

export default {
  name: "MainPage",

  components: {
    ButtonDefault,
  },
  data: () => ({
    services: [
      "Lizensverwaltung",
      "Installationsservice Einzelplatz und Server/Client",
      "Inbetriebnahmen",
      "Prozessintegration und Lösungen",
      "Schulungen",
      "Updateservice",
      "Hotline für Technik und Inhalt Lexwareprodukte",
      "Technischer Service Umfeld Lexware auch vor Ort",
      "Addon Service",
      "Sonderlösungen",
    ],
  }),
};
</script>

<style scoped>
h2 {
  font-size: 2em;
}
span,
a,
href,
b,
strong {
  font-size: 1.4em;
}

section {
  width: 100vw;
  padding: 0 0;
  margin: 0 0;
}

div {
  padding: 0px 0px;
  margin: 0px -1px;
}

.text-style {
  color: white;
}

.background-box {
  background: rgb(255, 255, 255, 0.6);
  color: black;
  padding: 15px;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

li {
  list-style: none;
}

@keyframes marquee {
  0% {
    text-indent: 100%;
  }

  100% {
    text-indent: -1424px;
  }
}

.marquee {
  font-size: 20px;
  width: 100%;
  margin: auto;
  padding: 2px;
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

.marquee > b > a {
  color: var(--color-ci-red);
}
</style>
