import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import "@mdi/font/css/materialdesignicons.css";

//Fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";



Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
